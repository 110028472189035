/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import type { AppProps } from 'next/app';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import posthog from 'posthog-js';
import 'antd/dist/reset.css';
import 'nprogress/nprogress.css';
import { SessionProvider } from 'next-auth/react';
import { getMyBaseInfo } from '../lib/util';

const queryClient = new QueryClient();

export default function App({ Component, pageProps }: AppProps) {
  const router = useRouter();

  useEffect(() => {
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_TOKEN!, {
      api_host: process.env.NEXT_PUBLIC_POSTHOG_API_HOST!,
      loaded: (ph) => {
        const productionDomains = ['meetenrich.com', 'expanded-learning-sausd.us', 'fsusd-elop.org'];
        // opt out of capturing if NEXT_PUBLIC_BASE is not in production
        if (!productionDomains.some((domain) => process.env.NEXT_PUBLIC_BASE!.includes(domain))) ph.opt_out_capturing();
      },
    });

    const handleRouteChange = () => posthog.capture('$pageview');
    router.events.on('routeChangeComplete', handleRouteChange);
    getMyBaseInfo().then((res) => {
      if (res) posthog.identify(res.id, { email: res.emailAddress });
    });

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <SessionProvider session={pageProps.session}>
      <QueryClientProvider client={queryClient}>
        <Component {...pageProps} />
      </QueryClientProvider>
    </SessionProvider>
  );
}
